<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sports-links-comp></sports-links-comp>
        <div class="sports_bet_list">
          <div class="button_group">
            <button class="btn02 bw5" :class="{'active_buttongroup_button': search.betResult === null}"
                    @click="changeBetResult(null)">전체
            </button>

            <button class="btn02 bw5" :class="{'active_buttongroup_button': search.betResult === sportsConst.BET_RESULT_WAITING}"
                    @click="changeBetResult(sportsConst.BET_RESULT_WAITING)">대기중
            </button>

            <button class="btn02 bw5" :class="{'active_buttongroup_button': search.betResult  === sportsConst.BET_RESULT_WIN}"
                    @click="changeBetResult(sportsConst.BET_RESULT_WIN)"> 당첨
            </button>

            <button class="btn02 bw5" :class="{'active_buttongroup_button': search.betResult  === sportsConst.BET_RESULT_LOSE}"
                    @click="changeBetResult(sportsConst.BET_RESULT_LOSE)"> 낙첨
            </button>

            <button class="btn02 bw5" :class="{'active_buttongroup_button': search.betResult  === sportsConst.BET_RESULT_CANCEL}"
                    @click="changeBetResult(sportsConst.BET_RESULT_CANCEL)"> 취소
            </button>
          </div>
          <!--배팅내역 리스트-->
          <sports-bet-list-comp
                  :bet-list="betList"
                  @cancelOrDelBet="cancelOrDelBet"
                  @betInfoChecked="betInfoChecked"
                  :position="1"></sports-bet-list-comp>

        </div>

        <pagination :page-index="pageNum"
                    :total="total"
                    :page-size="pageSize"
                    @change="pageChange"></pagination>

      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>
  </div>
</template>
<style scoped>

</style>
<script>
  import SubTitle from "../../components/SubTitle";
  import Pagination from "../../components/pagenation/Pagination";
  import RightBarComp from "../../components/RightBarComp";
  import sportsConst from "../../common/sportsConst";
  import {getBetList} from "../../network/sportsBetRequest";
  import {fix2, kindText} from "../../common/SportsUtils";
  import SportsBetListComp from "../../components/sports/SportsBetListComp";
  import BoardWriter from "../../components/BoardWriter";
  import LeftBarComp from "../../components/LeftBarComp";
  import TopbarComp from "../../components/TopbarComp";
  import FootComp from "../../components/FootComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
  import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
  import SportsLinksComp from "../../components/links/SportsLinksComp";

  export default {
    name: "SportsBetInfo",
    components: {
      SportsLinksComp,
      SportsBetCartComp,
      SportsLeftBarComp,
      RightBarBannerComp,
      FootComp,
      TopbarComp,
      LeftBarComp, BoardWriter, SportsBetListComp, SubTitle, Pagination, RightBarComp
    },
    data() {
      return {
        sportsConst,
        pageNum: 1,
        pageSize: 5,
        total: 1,
        orderBy: 'b.id DESC',
        search: {
          betResult: null
        },
        selectedBetIds: [],
        betList: []
      }
    },
    methods: {
      /*전체,당첨,낙첨,..버튼 클릭시*/
      changeBetResult(betResult) {
        this.pageNum = 1
        this.search.betResult = betResult
        this.initBetList()
      },
      /*SportsBetListComp.vue에서 취소 또는 삭제시 베팅내역 새로고침*/
      cancelOrDelBet() {
        this.initBetList()
      },
      /*SportsBetListComp.vue에서 체크박스 클릭시*/
      betInfoChecked(betIds) {
        this.selectedBetIds = betIds
      },
      initBetList() {
        getBetList(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {
          if (res.data.success) {
            this.total = res.data.total
            this.betList = res.data.data;
            this.betList.forEach((betInfo) => {
              betInfo.betInfoGameList.forEach((betInfoGame) => {
                //배당 소수점 두자리 설정 1.8 => 1.80
                fix2(betInfoGame)
                //승무패,핸디오언 텍스트 설정
                kindText(betInfoGame.game)
              })
            })
          }
        })
      },
      pageChange(page) {
        this.pageNum = page
        this.initBetList()
      }
    },
    created() {
      if(!this.$store.state.isLogin){
        this.$swal({
          title: '로그인이 필요한 서비스 입니다.',
          type: 'warning',
          showCancelButton: false,
          showConfirmButton: true
        })
        this.$router.push('/login')
        return false;
      }
      this.initBetList()
    }
  }
</script>
